<template>
  <div id="app">
    <div class="background-image">
      <img src="bg.png" alt="Background Image" class="background-img" />
    </div>
    <div class="content">
      <!-- <img src="/title.png" alt="title" class="title-image" /> -->

      <h1 class="title">VogueMeow</h1>
      <!-- 加hidden是为了隐藏标题 -->

      <p class="text">
        Meow~ Watch this kitty slay the fashion runway, dazzling everyone with its ever-changing styles! 
        Follow $VogueMeow into the realm of trendsetting, where it masters every look, 
        from streetwear to haute couture. Don't miss out on this fashion extravaganza! Join $VogueMeow,
         become a part of the trend, and let's rule Solana with style!
      </p>

      <div class="additional-text" @click="copyText">
        <span>CA:</span><span>EjJZct3iTox6XAfGdx4HjT3DS2gwtii3rrThKKpxpump</span>
      </div>

      <button class="button" onclick="window.location.href='https://pump.fun/EjJZct3iTox6XAfGdx4HjT3DS2gwtii3rrThKKpxpump'">
        <span class="buy-text">BUY</span>
      </button>
      
    </div>
    <div class="social-icons">
      <a href="https://x.com/voguemeow_sol" target="_blank">
        <img src="/icon_tw@3x.png" alt="Twitter" />
      </a>
      <a href="https://t.me/voguemeow" target="_blank">
        <img src="/icon_telg@3x.png" alt="Telegram" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    copyText() {
      const textToCopy = 'EjJZct3iTox6XAfGdx4HjT3DS2gwtii3rrThKKpxpump'; // 获取需要复制的文本
      navigator.clipboard.writeText(textToCopy) // 使用 Clipboard API 复制文本
        .then(() => {
          console.log('Text copied successfully!');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
        });
    }
  }
}
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'meihei';
  src: url('@/assets/fonts/meihei.ttf') format('truetype');
}

/* 全局设置字体 */
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'meihei';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .hidden {
  display: none;}这里是隐藏了标题 */

.content {
  position: absolute;
  top: 6%;
  left: 9%;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 3em; /* 增大标题字体 */
  margin-bottom: 20px; /* 缩小标题和文本之间的距离 */
}

.additional-text {
  font-size: 1em;
  color: #ffffff;
  cursor: pointer;
  margin-top: -50px; /* 向上移动 additional-text */
}

.additional-text:hover span:last-child {
  text-decoration: none; /* 去掉下划线 */
  color: #C4B1B4; /* 改变颜色 */
}

.text {
  font-size: 0.9em;
  max-width: 380px;
  overflow-wrap: break-word;
  line-height: 1.8; /* 缩短行间距 */
  margin-bottom: 70px;
  margin-top: 0; /* 去掉文本与标题之间的上边距 */
}

.button {
  margin-top: 30px;
  padding: 8px 50px;
  font-size: 2em;
  color: #C4B1B4; /* 按钮文字颜色 */
  font-family: 'meihei';
  background-color: transparent; /* 去掉按钮背景颜色 */
  border: 0.8px solid white; /* 按钮边框颜色 */
  border-radius: 1px; /* 缩小边框圆角弧度 */
  cursor: pointer;
  z-index: 1;
  display: flex;
}

.buy-text {
  margin-top: 0px; /* 调整 BUY 文字的上移量 */
  font-size: 1em;
  color: inherit; /* 按钮文字颜色 */
}

.button:hover {
  background-color: #C4B1B4; /* 鼠标悬停时背景颜色 */
  color: black; /* 鼠标悬停时文字颜色 */
  border: none; /* 鼠标悬停时去掉边框 */
}

.social-icons {
  position: absolute;
  top: 38%; /* 将图标移动到页面右边，中间偏上的位置 */
  right: 6.5%; /* 调整图标到页面右边 */
  display: flex;
  flex-direction: row; /* 水平排列 */
  align-items: flex-start;
}

.social-icons a {
  display: flex; /* 使图标居中 */
  align-items: center; /* 使图标垂直居中 */
  justify-content: center; /* 使图标水平居中 */
  width: 60px; /* 设置图标容器宽度 */
  height: 60px; /* 设置图标容器高度 */
  background-color: #1C1E22; /* 背景颜色 */
  opacity: 0.9; /* 设置不透明度为90% */
  border: none; /* 去掉边框 */
  border-radius: 50%; /* 圆形背景 */
  margin-left: 30px; /* 间距 */
  transition: background-color 0.3s, transform 0.3s; /* 加入背景颜色变化过渡 */
}

.social-icons a:first-child {
  margin-left: 0; /* 第一个图标没有左间距 */
}

.social-icons img {
  width: 26px; /* 缩小图标大小 */
  height: 26px; /* 缩小图标大小 */
}

.social-icons a:hover {
  background-color: #2E323A; /* 鼠标悬停时背景颜色 */
  opacity: 1; /* 鼠标悬停时不透明度变为100% */
  transform: scale(1.1);
}

/* .title-image {
  position: absolute;
  margin-top: -32%;
  width: auto;
  height: 125px;
  z-index: 1;
}*/
</style>
